import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Mobileviewcontainer from "./Mobile/views/Mobileviewcontainer";
import MapView from "./Mobile/views/MapView";
import {
  BrowserView,
  MobileView,
  TabletView,
  isBrowser,
  isTablet,
  isIOS,
  isMobileSafari,
  isMobile,
  isIPad13,
  isIOS13,
  deviceType
} from "react-device-detect";


function BountifulView(props){
  if(isMobile || isTablet || isIOS || isMobileSafari || isIOS13 || isIPad13 || deviceType === 'tablet'){
    return <Mobileviewcontainer />;
    //return <MapView />
  } else {
    return <App />;
  }
}

ReactDOM.render(
  <React.StrictMode>
	<BountifulView />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
